import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/8B/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/8B/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/8B/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/8B/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2024/8B/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2024/8B/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/2024/8B/7.jpg';







// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly8B2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/8B/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/8B/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/8B/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/8B/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/8B/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/class-assembly/8B/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/class-assembly/8B/7.webp`;




  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1.5,
        cols: 2,
      },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Non-Cooperation Movement 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 8  Date: 1-3 August 2024
        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Imagination is the beginning of creation. You imagine what you desire, you will what you imagine and at last,
   you create what you will"- George Bernard Shaw

<br/>
        </Typography> */}

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 8B presented their class assembly from 01 August 2024 to 03 August 2024, on the topic               ‘Non-Cooperation Movement’. Students shared insightful thoughts about the significance of the movement, discussing its impact on India's journey to independence and the values of nonviolent resistance. These reflections set a contemplative tone and prepared the audience for the following days' events.
        <br></br>
        The highlight of the second day was an evocative dance drama that vividly portrayed the Non-Cooperation Movement. Through expressive choreography, students illustrated the pivotal events and influential figures of the movement. The performance captured the spirit of unity and resistance, offering a dynamic and educational experience for all attendees. The final day featured an interactive quiz on the Non-Cooperation Movement, testing students' knowledge and understanding of the historical event. The quiz was both informative and engaging, encouraging active participation and learning. 
        <br></br>
        The assembly concluded with a melodious rendition of the classic song "Mera Jootha Hai Japani," reverberating that they are Indians by heart. The assembly provided a well-rounded exploration of the Non-Cooperation Movement and Indian cultural heritage. Through thoughtful discussions, a dramatic performance, an interactive quiz and a spirited musical number, students demonstrated their engagement with historical and cultural themes, creating a memorable and educational experience for all.
        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “True strength lies not in Physical Force, but in the courage to peacefully resist the Injustice”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly8B2024;